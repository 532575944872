( function( $ ) {

	"use strict";

	var M2BR = {
		'common': function() {

			var maskBehavior = function ( val ) {
				return val.replace( /\D/g, '' ).length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
			};

			$( '.telefone' ).mask( maskBehavior, {
				onKeyPress: function( val, e, field, options ) {
					field.mask( maskBehavior.apply( {}, arguments ), options );
				}
			});

			$('.menu-cardapio a[href^="#"').click(function (e) {
				var target = this.hash;
					target = $(target);

				e.preventDefault();

				$('html, body').stop().animate({
					'scrollTop':  target.offset().top
				}, 500);
			});

			$('.foto-modal').click(function (e) {
				e.preventDefault();
				console.log($('#galeria-modal .foto-galeria').attr('src', $(this).data('image')));
				$('#galeria-modal .foto-galeria').attr('src', $(this).data('image'));
				$('#galeria-modal').modal('show');
			});

		},
		'home': function() {

			$('.carousel').carousel();

			var carousel = $(".carousel");

			$('.carousel .item:first').addClass('active');

			var indicators = $(".carousel-indicators");
			carousel.find(".carousel-inner").children(".item").each(function (index) {
				if (index === 0) {
					indicators.append("<li data-target='#carousel' data-slide-to='" + index + "' class='active'></li>");
				} else {
					indicators.append("<li data-target='#carousel' data-slide-to='" + index + "'></li>");
				}
			});
		}
	};

	$( document ).ready( function() {
		$.each( document.body.className.replace( /-/g, '_' ).split( /\s+/ ), function( i, className ) {
			if ( typeof M2BR[className] === 'function' ) {
				M2BR[className]();
			}
		} );

		M2BR.common();
	} );

} )( jQuery );
